import React, { ForwardedRef, forwardRef } from "react";
import styled from "styled-components";

interface ButtonRow {
	clickInfo: () => void
}

const ButtonRowDiv = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding: var(--small-padding) var(--padding);
	display: flex;
	flex-direction: row;
	gap: var(--small-padding);
	justify-content: space-between;
	z-index: 50;
`;

const ButtonRow = forwardRef((
	{ clickInfo }: ButtonRow,
	ref: ForwardedRef<HTMLDivElement>
): JSX.Element => {
	// TODO: Removed to add again later - Opens a dialog to show a site map.
	// const closeSiteMap = () => {
	// 	window.location.hash = "";
	// 	document.body.style.overflow = "inherit";
	// 	showModal(false);
	// };

	// const clickShowSiteMap = () => {
	// 	window.location.hash = "#map";
	// 	document.body.style.overflow = "hidden";
	// 	showModal(<SiteMap closeModal={closeSiteMap} />);
	// };

	const openDonate = () => {
		window.open("https://www.peoplesfundraising.com/donation/salisbury-transition-city-donations", "_blank");
	};

	return (
		<ButtonRowDiv ref={ref}>
			{/* TODO: Removed to add again later - Dialog to show a site map.
			<button type="button" aria-label="Map" className="button roundButton"
			 onClick={() => clickShowSiteMap()}>
				<FontAwesomeIcon icon={faMap} />
			</button> */}
			<button type="button" className="button" onClick={clickInfo}>
				Information
			</button>
			<div style={{ flex: "1 2 auto" }} />
			<button type="button" className="button accentButton" onClick={openDonate}>Donate Now</button>
		</ButtonRowDiv>
	);
});

export default ButtonRow;
