import { MothershipSoftware, SOS } from "components/sponsors";
import React from "react";
import {
	IconMusic, IconToilets, IconTipi
} from "./guestIcons";

import bannerImage from "../images/main.jpg";
import placeholder from "../images/placeholder.jpg";

export const MAX_BUTTON_ROW_OPACITY = 1;
export const SITE_CENTRE_LOCATION = [51.06777, -1.80262] as [number, number];
export const stages: Record<string, StageInfo> = {
	stage1: {
		id: "main",
		name: "Main Stage",
		icon: <IconMusic />,
		image: "/images/site/mainstage.jpg",
		host: <SOS />
	},
	stage2: {
		id: "youth",
		name: "Youth Stage",
		icon: <IconMusic />,
		image: "/images/site/youthstage.jpg",
		sponsor: <MothershipSoftware />
	}
} as Record<string, StageInfo>;

export const otherLocations: Record<string, StageInfo> = {
	location1: {
		id: "toilets",
		name: "Toilets",
		icon: <IconToilets />,
		image: "/images/site/toilets.jpg",
	},
	location2: {
		id: "tipi",
		name: "Tipi",
		icon: <IconTipi />,
		image: "/images/site/tipi.jpg",
	}
};

export const apiUrl = "https://peopleinthepark.org/api/index.php";

export const defaultBandImage = "/images/site/guitar.jpg";
export const defaultPlaceholderImage = placeholder;
export const placeholderImage = bannerImage;
