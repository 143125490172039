import React from "react";
import styled from "styled-components";

const PIPDiv = styled.div`
	display: flex;
	flex-direction: column;
	img {
		width: 90%;
		object-fit: contain;
		padding: var(--padding);
		margin: 0 auto;
	}
	iframe {
		width: 100%;
		aspect-ratio: 4 / 3;
	}
`;

const PeopleInThePark = (): JSX.Element => (
	<PIPDiv>
		<div className="columns">
			<div className="column">
				<h4>Free Entry!</h4>
				<p>
					People in the Park is an un-ticketed event and consists of a mix of community
					group stallholders, performers, Local environmentally friendly businesses,
					live music and guest speakers.
				</p>
				<p>
					This year we are once again taking over more of Lizzy gardens and have added
					more stalls, food, sellers and our new Camp Fire discussion area.
				</p>
				<p>
					People in the Park is an opportunity for the community of Salisbury to learn from
					each other, exchange ideas and generate plans for working together
					to help us all to create a more ethical, just and sustainable future for Salisbury.
				</p>
			</div>
			<div className="column">
				<iframe width="560" height="315" src="https://www.youtube.com/embed/Yl3xrPwcxnU?si=81qckFPHjy1TEI2h" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
			</div>
		</div>
	</PIPDiv>
);

export default PeopleInThePark;
