import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faMusic, faBagShopping, faUtensils, faBullhorn, faRestroom, faChildReaching, faPeopleGroup,
	faComment, faLocationPin, faComments, faBookOpenReader, faCampground, faHandHoldingHeart
} from "@fortawesome/free-solid-svg-icons";

export const IconDiscussion = ():JSX.Element => <FontAwesomeIcon className="guestIcon" icon={faComments} />;
export const IconSpeaker = ():JSX.Element => <FontAwesomeIcon className="guestIcon" icon={faBullhorn} />;
export const IconPoet = ():JSX.Element => <FontAwesomeIcon className="guestIcon" icon={faComment} />;
export const IconMusic = ():JSX.Element => <FontAwesomeIcon className="guestIcon" icon={faMusic} />;
export const IconShopping = ():JSX.Element => <FontAwesomeIcon className="guestIcon" icon={faBagShopping} />;
export const IconNonProfit = ():JSX.Element => <FontAwesomeIcon className="guestIcon" icon={faHandHoldingHeart} />;
export const IconCommunity = ():JSX.Element => <FontAwesomeIcon className="guestIcon" icon={faPeopleGroup} />;
export const IconFood = ():JSX.Element => <FontAwesomeIcon className="guestIcon" icon={faUtensils} />;
export const IconLocation = ():JSX.Element => <FontAwesomeIcon className="guestIcon" icon={faLocationPin} />;
export const IconStoryTime = ():JSX.Element => <FontAwesomeIcon className="guestIcon" icon={faBookOpenReader} />;
export const IconToilets = ():JSX.Element => <FontAwesomeIcon className="guestIcon" icon={faRestroom} />;
export const IconTipi = ():JSX.Element => <FontAwesomeIcon className="guestIcon" icon={faCampground} />;
export const IconActivity = ():JSX.Element => <FontAwesomeIcon className="guestIcon" icon={faChildReaching} />;

const getIconForGuest = (guestType: string): JSX.Element => {
	switch (guestType) {
		case "speaker": return <IconSpeaker />;
		case "poet": return <IconPoet />;
		case "music": return <IconMusic />;
		case "business": return <IconShopping />;
		case "not-for-profit": return <IconNonProfit />;
		case "food": return <IconFood />;
		case "community": return <IconCommunity />;
		case "storytelling": return <IconStoryTime />;
		case "activity": return <IconActivity />;
		default: return <IconLocation />;
	}
};

export default getIconForGuest;
