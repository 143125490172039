import HeaderPanel from "components/headerPanel";
import React, {
	ReactNode,
	useCallback,
	useEffect, useRef, useState
} from "react";
import "./App.css";
import SectionPanel from "./components/sectionPanel";
import { MAX_BUTTON_ROW_OPACITY } from "./common/constants";
import PIPLogo from "./images/pitp-logo.png";
import ButtonRow from "./components/buttonRow";
import SalisburyTransitionCity from "./information/salisburyTransitionCity";
import PeopleInThePark from "./information/peopleInThePark";
import { EventsProvider } from "./hooks/useEvents";
import Footer from "./components/footer";
import BannerImage from "./images/main.jpg";
import InformationListing, { InformationType } from "./components/informationListing";
import { VendorsProvider } from "./hooks/useVendors";

function App(): JSX.Element {
	const headerDiv = useRef<HTMLDivElement>(null);
	const buttonRowDiv = useRef<HTMLDivElement>(null);
	const divInfo = useRef<HTMLDivElement>(null);
	const [modal, showModal] = useState<ReactNode>();

	const clickInfo = () => {
		if (divInfo.current && buttonRowDiv.current) {
			window.location.hash = "#info";
			window.scrollTo({
				top: divInfo.current.offsetTop - buttonRowDiv.current.offsetHeight
			});
		}
	};

	useEffect(() => {
		const checkScroll = () => {
			const head = headerDiv.current;
			const btn = buttonRowDiv.current;
			if (head && btn) {
				const headHeight = head.offsetHeight / 2;
				const mv = Math.abs(
					document.documentElement.scrollTop - headHeight
				) / (btn.offsetHeight * 2);
				const op = mv > 1 ? MAX_BUTTON_ROW_OPACITY : mv;
				const opacity = document.documentElement.scrollTop - headHeight > 0
					? op : 0;
				btn.style.backgroundColor = (document.documentElement.scrollTop > headHeight - btn.offsetHeight) ? `rgba(0, 0, 0, ${opacity})` : "inherit";
			}
		};

		document.addEventListener("scroll", checkScroll);

		return () => {
			document.removeEventListener("scroll", checkScroll);
		};
	}, []);

	useEffect(() => {
		window.onhashchange = () => {
			if (window.location.hash === "" || window.location.hash === "#") {
				document.body.style.overflow = "inherit";
				showModal(null);
			}
		};

		return () => {
			window.onhashchange = null;
		};
	}, [showModal]);

	const openSignupPage = useCallback(() => {
		window.open("https://dashboard.mailerlite.com/forms/393748/132010665658287212/share", "_blank");
	}, []);

	return (
		<>
			<div className="App">
				<HeaderPanel
					bannerImage={BannerImage}
					logoImage={PIPLogo}
					alt="People In The Park"
					ref={headerDiv}
					cover
				>
					<h1>PEOPLE IN THE PARK</h1>
					<p>
						<b>
							FREE ENTRY!
							<br />
							17th May 2025. 10am - 4pm
						</b>
						<br />
						Queen Elizabeth Gardens, Salisbury. UK.
					</p>
				</HeaderPanel>
				<SectionPanel heading="Sign up for 2025!">
					<div className="columns">
						<div className="column">
							<p>
								If you&apos;re interested in being a stallholder for People In The Park 2025
								then sign up to our mailing list below. By doing this, you will be the first to
								know about all of the latest arrangements and when we start taking bookings for
								2025.
							</p>
							<p>
								We&apos;re hoping to make this year even better than last year with a new layout for
								our business area, a change to allow you to request whether you want to be in the
								&quot;thick of it&quot; where it might be noisier or whether you want a quieter
								spot. We&apos;ve tried to listen to the feedback from stall holders last year to
								make it even better for you.
							</p>
						</div>
						<div
							className="column"
							style={{
								display: "flex",
								alignItems: "center"
							}}
						>
							<button
								className="button accentButton"
								onClick={openSignupPage}
								type="button"
								style={{
									fontSize: "xx-large",
									padding: "15px"
								}}
							>
								Sign Up Now!
							</button>
						</div>
					</div>
				</SectionPanel>
				<SectionPanel heading="Want to know more?">
					<div>
						<p>
							Probably the best way to find out more about People in the Park is
							to look at our previous events and also news and other articles about
							the event. Take a look below to find out more:
						</p>
						<ul>
							<li><a href="./pitp2024">People in the Park 2024</a></li>
							<li>
								<a href="https://www.salisburyjournal.co.uk/news/24331084.23-best-photos-people-park-2024/" target="_blank" rel="noreferrer">
									Salisbury Journal: The 23 best photos from People in the Park 2024
								</a>
							</li>
							<li><a href="./pitp2023">People in the Park 2023</a></li>
						</ul>
					</div>
				</SectionPanel>
				{ /* <EventsProvider stage="main">
					<InformationListing id="main" size="poster"
					 numberToShow={2} showModal={showModal} type={InformationType.event} />
				</EventsProvider>
				<EventsProvider stage="youth">
					<InformationListing id="youth" numberToShow={3}
					 showModal={showModal} type={InformationType.event} />
				</EventsProvider>
				<VendorsProvider vendorType="food">
					<InformationListing
						id="food"
						numberToShow={4}
						type={InformationType.vendors}
						size="normal"
						heading="Food Vendors"
						showModal={showModal}
					/>
				</VendorsProvider>
				<VendorsProvider vendorType="business">
					<InformationListing
						id="business"
						numberToShow={4}
						type={InformationType.vendors}
						size="round"
						heading="Local Businesses"
						showModal={showModal}
					/>
				</VendorsProvider>
				<VendorsProvider vendorType="not-for-profit">
					<InformationListing
						id="nonProfit"
						numberToShow={4}
						type={InformationType.vendors}
						size="round"
						heading="Not for profit &amp; Charities"
						showModal={showModal}
					/>
				</VendorsProvider>
				<VendorsProvider vendorType="community">
					<InformationListing
						id="community"
						numberToShow={4}
						type={InformationType.vendors}
						size="round"
						heading="Community Groups"
						showModal={showModal}
					/>
				</VendorsProvider> */ }
				<div ref={divInfo} />
				<SectionPanel heading="People In The Park">
					<PeopleInThePark />
				</SectionPanel>
				<SectionPanel heading="Salisbury Transition City">
					<SalisburyTransitionCity />
				</SectionPanel>
				<ButtonRow ref={buttonRowDiv} clickInfo={clickInfo} />
				{ modal }
			</div>
			<Footer />
		</>
	);
}

export default App;
