import React, { ReactNode, ForwardedRef, forwardRef } from "react";
import styled from "styled-components";
import banner from "../images/banner.jpg";

interface ImageDivProps {
	bannerImage?: string,
	logoImage?: string,
	cover?: boolean
}

const HeaderDiv = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height:  50vh;
	min-height: -webkit-fit-content;
	overflow: hidden;

	&::after, &::before {
		content: "";
		background-image: url(${(props: ImageDivProps) => (
		props.bannerImage
	)});
		background-size: ${({ cover }: ImageDivProps) => (cover ? "cover" : "contain")};
		background-position: center;
		width: 100%;
		height: 100%;
		display: block;
		position: absolute;
		top: 0px;
		left: 0px;
	}

	@media (max-width: 600px) {
		height: ${({ cover }: ImageDivProps) => (cover ? "40vh" : "30vh")};
	}

	/* the blurred image */
	&::before{
		content: "";
		background-repeat: repeat;
		filter: blur(8px);
		transform: scaleX(-1);
	}
	/* the clear image */
	&::after{
		content: "";
		background-repeat: no-repeat;
	}

	.logoContainer {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
	}

	.logoImage {
		max-width: 500px;
		justify-self: center;
		height: 100%;
	}

	.detailLogoImage {
		width: 150px;
		height: 150px;
		background: url(${(props: ImageDivProps) => (
		props.logoImage
	)});
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		background-color: #fff;
		background-origin: content-box;
		padding: var(--small-padding);

		@media (max-width: 700px) {
			width: 75px;
			height: 75px;
		}
	}

	.light {
		background-color: #000;
	}

	.headerContent {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		min-height: 100%;
		width: 100%;
		max-width: 1200px;
		margin: 0 auto;
		padding: var(--padding);
		color: var(--white-color);
		z-index: 2;

		@media (max-height: 700px) {
			flex-direction: row-reverse;
			justify-content: space-between;
			align-items: flex-end;
			padding: 63px 10px 10px;
		}
		@media (max-width: 600px) {
			padding: 63px 0px 0px;
		}

		h1 {
			width: -webkit-fit-content;
			padding: var(--small-padding);
			background-color: var(--white-color);
			color: var(--black-color);
			margin-bottom: 0px;
		}

		p {
			width: -webkit-fit-content;
			padding: var(--small-padding);
			background-color: var(--black-color);
			color: var(--white-color);
			margin-bottom: 0;
		}

		.headerContentInfo {
			display: flex;
			flex-direction: column;

			@media (max-height: 700px), (max-width: 600px) {
				flex-direction: ${({ cover }) => (cover ? "column" : "row")};
				align-items: ${({ cover }) => (cover ? "flex-start" : "flex-end")};
			}
		}
	}
`;

interface HeaderPanel {
	alt?: string,
	logoImage?: string | null,
	children?: ReactNode,
	bannerImage?: string | null,
	smallLogo?: boolean | false,
	lightLogo?: boolean,
	cover?: boolean
}

const HeaderPanel = forwardRef((
	{
		alt, logoImage, bannerImage, smallLogo, children, lightLogo = false, cover = false
	}: HeaderPanel,
	ref: ForwardedRef<HTMLDivElement>
): JSX.Element => (
	<HeaderDiv
		bannerImage={bannerImage ?? banner}
		logoImage={logoImage ?? ""}
		ref={ref}
		cover={cover}
	>
		<div className="headerContent">
			{ !smallLogo && logoImage ? (
				<div className="logoContainer">
					<img
						src={logoImage}
						alt={alt}
						className="logoImage"
					/>
				</div>
			) : null }
			<div className="headerContentInfo">
				{ smallLogo && logoImage ? <div className={["detailLogoImage", lightLogo ? "light" : ""].join(" ")} aria-details={alt} /> : null }
				{ children }
			</div>
		</div>
	</HeaderDiv>
));

HeaderPanel.defaultProps = {
	alt: "",
	logoImage: null,
	children: null,
	bannerImage: null,
	smallLogo: false
};

export default HeaderPanel;
