import React from "react";
import SmallModal from "./smallModal";

interface LegalInfoProps {
	closeModal: () => void
}

export const Disclaimer = ({ closeModal }: LegalInfoProps): JSX.Element => (
	<SmallModal closeModal={closeModal}>
		<h1>DISCLAIMER</h1>
		<strong>Last updated: August 24, 2022 </strong>
		<h3>WEBSITE DISCLAIMER</h3>
		<p>
			The information provided by Salisbury Transition City (&quot;we&quot;, &quot;us&quot;, or
			&quot;our&quot;) on
			{" "}
			<a href="https://www.peopleinthepark.org/" target="_blank" rel="noreferrer">
				https://www.peopleinthepark.org/
			</a>
			{" "}
			(the &quot;Site&quot;) and our mobile application is for general informational purposes
			only. All information on the Site and our mobile application is provided in good faith,
			however we make no representation or warranty of any kind, express or implied, regarding
			the accuracy, adequacy, validity, reliability, availability, or completeness of any
			information on the Site or our mobile application. UNDER NO CIRCUMSTANCE SHALL WE HAVE
			ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE
			OF THE SITE OR OUR MOBILE APPLICATION OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE
			AND OUR MOBILE APPLICATION. YOUR USE OF THE SITE AND OUR MOBILE APPLICATION AND YOUR
			RELIANCE ON ANY INFORMATION ON THE SITE AND OUR MOBILE APPLICATION IS SOLELY AT YOUR OWN RISK.
		</p>
		<h3>EXTERNAL LINKS DISCLAIMER</h3>
		<p>
			The Site and our mobile application may contain (or you may be sent through the Site or
			our mobile application) links to other websites or content belonging to or originating from
			third parties or links to
			websites and features in banners or other advertising. Such external links are
			not investigated, monitored, or checked for accuracy, adequacy, validity, reliability,
			availability, or completeness by us. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR
			ASSUME RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY INFORMATION
			OFFERED BY THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR
			FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE WILL NOT BE A PARTY TO OR
			IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS
			OF PRODUCTS OR SERVICES.
		</p>
		<p>
			<small>
				This disclaimer was created using Termly&apos;s
				{" "}
				<a href="https://termly.io/products/disclaimer-generator/" target="_blank" rel="noreferrer">
					Disclaimer Generator.
				</a>
			</small>

		</p>
	</SmallModal>
);

export const Copyright = ({ closeModal }: LegalInfoProps): JSX.Element => (
	<SmallModal closeModal={closeModal}>
		<h1>Copyright and acknowledgement notices</h1>
		<p>
			&copy; Copyright 2024, Mothership Software Limited. All website code, layout and design work.
			All Rights Reserved.
		</p>
		<p>
			The copyright for all images of vendors, bands and other performers are owned by those
			vendors, bands or performers. All Rights Reserved.
		</p>
		<p>
			The copyright for all products or designs depicted on this website are owned by their
			respective copyright holders. All Rights Reserved.
		</p>
		<p>
			The following attributions are given for the following images on
			{" "}
			<a href="https://unsplash.com/?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
				Unsplash
			</a>
			:
			<ul>
				<li>
					Image of guitarist: Photo by
					{" "}
					<a href="https://unsplash.com/@jefflssantos?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
						Jefferson Santos
					</a>
				</li>
				<li>
					Image of tipi: Photo by
					{" "}
					<a href="https://unsplash.com/@mebrooks01?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
						Malachi Brooks
					</a>
				</li>
				<li>
					Image of DJ decks: Photo by
					{" "}
					<a href="https://unsplash.com/@marcelalaskoski?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
						Marcela Laskoski
					</a>
				</li>
			</ul>
		</p>
	</SmallModal>
);
