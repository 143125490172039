import React from "react";
import styled from "styled-components";
import MothershipLogo from "../images/mothership-logo.svg";
import SOSLogo from "../images/sos.jpg";
import RedsLogo from "../images/reds.jpg";

interface SponsorProps {
	className?: string
}

interface SponsorButton {
	center: boolean
}

const SponsorButton = styled.button`
	display: flex;
	flex-direction: column;
	width: 100%;
	background: none;
	border: none;
	padding: 0;
	font-size: 0.875rem;
	align-items: center;

	img, svg {
		height: 70px;
		margin-top: var(--padding);
		margin-bottom: 5px;
	}
`;

export const MothershipSoftware = ({ className }: SponsorProps): JSX.Element => (
	<SponsorButton className={className} onClick={() => window.open("https://wearemothership.com", "_blank")}>
		<img src={MothershipLogo} alt="Mothership Software" />
		<div>Proudly Sponsored by Mothership Software</div>
	</SponsorButton>
);

export default { MothershipSoftware };

export const SOS = ({ className }: SponsorProps): JSX.Element => (
	<SponsorButton className={className} onClick={() => window.open("https://soundsofsalisburypodcast.podbean.com/", "_blank")}>
		<img src={SOSLogo} alt="Sounds of Salisbury" />
		<div>Hosted by Alex and Ellen from Sounds of Salisbury</div>
	</SponsorButton>
);

export const Reds = ({ className }: SponsorProps): JSX.Element => (
	<SponsorButton className={className} onClick={() => window.open("https://www.salisburyreds.co.uk/", "_blank")}>
		<img src={RedsLogo} alt="Salisbury Reds" />
		<div>Proudly sponsored by Salisbury Reds</div>
	</SponsorButton>
);
