import React from "react";
import styled from "styled-components";
import STCLogo from "../images/stcLogo.webp";

const STCDiv = styled.div`
	display: flex;
	flex-direction: column;

	img {
		width: 90%;
		object-fit: contain;
		padding: var(--padding);
		margin: 0 auto;
	}

	iframe {
		width: 100%;
		aspect-ratio: 4 / 3;
		border-radius: var(--border-radius)
	}
`;

const SalisburyTransitionCity = (): JSX.Element => (
	<STCDiv>
		<div className="columns">
			<div className="column">
				<h4>Our mission</h4>
				<p>
					To be a catalyst for positive change and create a
					better Salisbury through Networking, Education, Inspiration and
					the exploration of zero carbon solutions within our own communities.
				</p>
				<h4>About</h4>
				<p>
					Salisbury Transition City aims to be a catalyst and partner to inspire,
					encourage and enable those who want to develop a plan for a sustainable
					Salisbury.  We cannot achieve much alone but when we work and learn from
					each other, playing to our individual strengths, we can achieve real change.
				</p>
			</div>
			<div className="column">
				<p>
					We are not politically affiliated to any party or movement; the issues are too
					large and important for that, because we are a grassroots movement, the drive
					for change will come from the community.
				</p>
				<p>
					STC can trace its roots (and many of it&apos;s members) to 1992 and the Agenda 21
					movement that formed after COP &apos;92 in Rio.  By understanding where we have come
					from, the challenges we have faced and celebrating the many successes along the
					way, we can build a better Salisbury together.
				</p>
			</div>
			<div className="column">
				<img src={STCLogo} width="250px" alt="People in the park logo" />
				<h3 style={{ textAlign: "center" }}>
					<a href="https://www.transitionsalisbury.org">transitionsalisbury.org</a>
				</h3>
			</div>
		</div>
	</STCDiv>
);

export default SalisburyTransitionCity;
