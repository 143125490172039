import React, {
	ReactNode, forwardRef, ForwardedRef
} from "react";
import styled from "styled-components";

interface SectionPanel {
	heading?: string,
	children?: ReactNode,
	sponsor?: ReactNode,
	host?: ReactNode,
	time?: string,
	scrolling?: boolean,
	flexDirection?: "row" | "column",
	icon?: ReactNode
}

interface SectionDiv {
	$scrolling?: boolean,
	$flexDirection: "row" | "column"
}

const SectionDiv = styled.div`
	padding: calc(var(--padding) * 2) var(--padding);
	overflow: hidden;
	border-bottom: 1px solid rgba(0,0,0,0.1);
	max-width: 1200px;
	margin: 0 auto;

	.header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 0;

		.headerTextWrapper {
			display: flex;
			flex-direction: row;
		}

		h3 {
			margin-bottom: 5px;
		}
	}

	.distance {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: var(--small-padding);
	}

	.direction {
		font-size: 1.25rem;
		color: #007AFF;
	}

	.panelsDiv {
		display: flex;
		flex-flow: ${(props: SectionDiv) => (props.$flexDirection)};
		scroll-snap-type: ${(props: SectionDiv) => (props.$scrolling ? "x mandatory" : "")};
		overflow-x: ${(props: SectionDiv) => (props.$scrolling ? "auto" : "inherit")};
		overflow-y: ${(props: SectionDiv) => (props.$scrolling ? "hidden" : "inherit")};
		justify-content: flex-start;
	}

	.sponsorDiv {
		display: flex;
		width: 100%;
		flex-flow: row;
		justify-content: flex-start;
	}

	.hostDiv {
		display: flex;
		width: 100%;
		flex-flow: column;
		justify-content: flex-start;
	}

	.guestIcon {
		margin-right: var(--small-padding);
		color: rgba(0,0,0,0.25);
	}
`;

const SectionPanel = forwardRef((
	{
		heading, children, sponsor, time, scrolling = false, flexDirection = "row", icon, host
	}: SectionPanel,
	ref: ForwardedRef<HTMLDivElement>
): JSX.Element => (
	<SectionDiv
		ref={ref}
		$scrolling={scrolling}
		$flexDirection={flexDirection}
	>

		<div className="header">
			{ heading && (
				<div className="headerTextWrapper">
					<h3>
						{ icon }
					</h3>
					<div>
						<h3>{ heading }</h3>
						{ time && (
							<div>
								{new Date(time).toLocaleString(
									"en-GB",
									{
										hour: "2-digit", minute: "2-digit", weekday: "long", year: "numeric", month: "long", day: "numeric", timeZone: "Europe/London"
									}
								)}
							</div>
						)}
					</div>
				</div>
			)}
		</div>
		<div className="panelsDiv">{children}</div>
		{ (sponsor || host) && (
			<div className="sponsorDiv">
				{sponsor}
				{host}
			</div>
		)}
	</SectionDiv>
));

SectionPanel.defaultProps = {
	heading: undefined,
	sponsor: null,
	children: null,
	time: undefined,
	scrolling: false,
	flexDirection: "row",
	icon: null
};

export default SectionPanel;
