import React, { ReactNode } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

interface SmallModalDiv {
	size: "medium" | "large",
	scrolling: boolean
}

const SmallModalDiv = styled.div`
	position: fixed;
	width: ${(props: SmallModalDiv) => (props.size === "medium" ? "50%" : "80%")};
	height: ${(props: SmallModalDiv) => (props.size === "medium" ? "50%" : "80%")};
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: white;
	overflow-x: hidden;
	overflow-y: ${(props: SmallModalDiv) => (props.scrolling ? "scroll" : "hidden")};;
	z-index: 2000;
	padding: var(--medium-padding);
	border-radius: var(--border-radius);
	border: 1px solid var(--black-color);

	.buttonRow {
		position: fixed;
		top: var(--padding);
		right: var(--padding);
		z-index: 2001;
	}

	@media (max-width: 800px) {
		height: 100%;
		width: 100%;
		border-radius: 0;
	}
`;

const UnclickableDiv = styled.div`
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(0,0,0,0.5);
	z-index: 1099;
`;

interface SmallModal {
	scrolling?: boolean,
	size?: "medium" | "large"
	children: ReactNode,
	closeModal: () => void
}

const SmallModal = ({
	children, closeModal, size = "medium", scrolling = true
}: SmallModal): JSX.Element => (
	<>
		<UnclickableDiv />
		<SmallModalDiv size={size} scrolling={scrolling}>
			<div className="buttonRow">
				<button aria-label="Close" onClick={() => closeModal()} className="button largeButton roundButton" type="button">
					<FontAwesomeIcon icon={faXmark} />
				</button>
			</div>
			{ children }
		</SmallModalDiv>
	</>
);

SmallModal.defaultProps = {
	size: "medium",
	scrolling: true
};

export default SmallModal;
